import React, { useState, useEffect } from 'react';
import './DiplomadoEnfermeriaAuxiliar.css';
import temarioPDF from '../assets/temario_enfermeria_auxiliar.pdf';
import enfermeriaEscuela from '../Imagenes/Diplomadoenfermeria.jpeg';
import enfermeriaCarrera from '../Imagenes/Diplomado_enfermeria.jpeg';
import imagen3 from '../Imagenes/Diplomado_enfermeria2.jpeg';

function DiplomadoEnfermeriaAuxiliar() {
  const images = [
    { src: enfermeriaEscuela, caption: 'Atención a los pacientes en sus necesidades básicas' },
    { src: enfermeriaCarrera, caption: 'Supervisión de signos vitales' },
    { src: imagen3, caption: 'Apoyo en la administración de medicamentos' },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const nextImage = () => {
    setCurrentIndex((currentIndex + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentIndex((currentIndex - 1 + images.length) % images.length);
  };

  useEffect(() => {
    const interval = setInterval(nextImage, 5000);
    return () => clearInterval(interval);
  },);

  return (
    <div className="diplomado-enfermeria-auxiliar">
      <h1>Diplomado en Enfermería Auxiliar</h1>
      <p>
        La Enfermería Auxiliar es una profesión que consiste en brindar atención básica de la salud a los pacientes.
      </p>
      <ul>
        <li>Ayudan a los pacientes a vestirse, ir al baño y comer.</li>
        <li>Realizan la higiene personal de los pacientes.</li>
        <li>Registran signos vitales como temperatura, peso y pulso.</li>
        <li>Supervisión de medicamentos.</li>
        <li>Ordenar la ropa y camas.</li>
        <li>Siempre bajo la supervisión de una enfermera titulada.</li>
      </ul>

      {/* Carrusel de fotos */}
      <div className="carrusel">
        <button className="prev" onClick={prevImage}>⬅️</button>
        <div className="image-container">
          <img src={images[currentIndex].src} alt={`Imagen ${currentIndex + 1}`} />
          <p className="caption">{images[currentIndex].caption}</p>
        </div>
        <button className="next" onClick={nextImage}>➡️</button>
      </div>

      {/* Descarga del PDF con el temario */}
      <div className="temario">
        <h2>Descargar Temario</h2>
        <a href={temarioPDF} download="Temario_Enfermeria_Auxiliar.pdf">
          <button className="btn-descargar">Descargar PDF</button>
        </a>
      </div>

      {/* Botón de contacto */}
      <div className="contacto">
        <button className="btn-contacto" onClick={() => window.location.href = '/contacto'}>
          Contáctanos
        </button>
      </div>
    </div>
  );
}

export default DiplomadoEnfermeriaAuxiliar;
