import React, { useEffect, useRef } from 'react';
import './OfertaEducativa.css'; // Importar estilos
import LicenciaturaE from '../Imagenes/Licenciatura_enfermeria.jpeg';
import diplomadoTM from '../Imagenes/Diplomado_masajes.jpeg';
import diplomadoEA from '../Imagenes/diplomadoEA.jpeg';

function OfertaEducativa() {
  const licenciaturaRef = useRef(null);
  const diplomadoEARef = useRef(null);
  const diplomadoGerontologiaRef = useRef(null);
  const diplomadoTMRef = useRef(null);
  const bachilleratoRef = useRef(null);

  const handleScroll = () => {
    const refs = [
      licenciaturaRef, 
      diplomadoEARef, 
      diplomadoGerontologiaRef, 
      diplomadoTMRef, 
      bachilleratoRef
    ];
    const windowHeight = window.innerHeight;

    refs.forEach(ref => {
      const elementTop = ref.current.getBoundingClientRect().top;
      if (elementTop < windowHeight - 100) {
        ref.current.classList.add('aparece');
      }
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className="oferta-educativa">
      <h1>Nuestra Oferta Educativa</h1>
      <p className="texto-introductorio">
        En nuestra institución ofrecemos diversas opciones académicas que se adaptan a las necesidades de cada estudiante.
        Ya sea que busques profesionalizarte, obtener nuevas habilidades o culminar tus estudios, aquí encontrarás la opción ideal.
      </p>
      <div ref={licenciaturaRef} className="Licenciatura-Enfermeria seccion-educativa izquierda">
        <img src={LicenciaturaE} alt="Licenciatura en Enfermería" className="imagen-educativa" />
        <div className="contenido-educativo">
          <h2>Licenciatura en Enfermería</h2>
          <p>Contamos con dos modalidades: semipresencial con duración de tres años con bachillerato concluido.
            Y profesionalización a un año si ya tienes conocimiento previo en el área de la salud como ser auxiliar o técnico.
          </p>
          <button className="btn-contacto" onClick={() => window.location.href = '/licenciatura-en-enfermeria'}>
            Más Información
          </button>
        </div>
      </div>

      <div ref={diplomadoEARef} className="seccion-educativa derecha">
        <img src={diplomadoEA} alt="Diplomado en Enfermería Auxiliar" className="imagen-educativa" />
        <div className="contenido-educativo">
          <h2>Diplomado en Enfermería Auxiliar</h2>
          <p>En este diplomado la duración es de un año con clases presenciales solo un día a la semana a partir de los 15 años.</p>
          <button className="btn-contacto" onClick={() => window.location.href = '/diplomado-enfermeria-auxiliar'}>
            Más Información
          </button>
        </div>
      </div>

      <div ref={diplomadoGerontologiaRef} className="seccion-educativa izquierda">
        <img src="/ruta_de_imagen_gerontologia.jpg" alt="Diplomado en Gerontología" className="imagen-educativa" />
        <div className="contenido-educativo">
          <h2>Diplomado en Gerontología</h2>
          <p>Este diplomado tiene una duración de ocho meses, enfocado en atender las necesidades del adulto mayor.</p>
          <button className="btn-contacto" onClick={() => window.location.href = '/contacto'}>
            Contáctanos
          </button>
        </div>
      </div>

      <div ref={diplomadoTMRef} className="seccion-educativa derecha">
        <img src={diplomadoTM} alt="Diplomado en Terapias de Masaje" className="imagen-educativa" />
        <div className="contenido-educativo">
          <h2>Diplomado en Terapias de Masaje</h2>
          <p>Diplomado en línea o presencial con duración de seis meses dirigido a personas a partir de los 16 años.</p>
          <button className="btn-contacto" onClick={() => window.location.href = '/diplomado-terapias-de-masaje'}>
            Más Información
          </button>
        </div>
      </div>

      <div ref={bachilleratoRef} className="seccion-educativa izquierda">
        <img src="/ruta_de_imagen_bachillerato.jpg" alt="Bachillerato a un Examen" className="imagen-educativa" />
        <div className="contenido-educativo">
          <h2>Bachillerato a un Examen</h2>
          <p>Obtén tu bachillerato con un examen. El trámite de tu documento tardará cuatro meses.</p>
          <button className="btn-contacto" onClick={() => window.location.href = '/contacto'}>
            Contáctanos
          </button>
        </div>
      </div>
    </div>
  );
}

export default OfertaEducativa;
