import React, { useState } from 'react';
import './LicenciaturaEnEnfermeria.css'; // Asegúrate de tener esta hoja de estilos
import temarioPDF from '../assets/temario_licenciatura_en_enfermeria.pdf';
import LicenciaturaEnfermeria from '../Imagenes/Licenciaturaenfermeria.jpeg';
import Licenciatura_enfermeria from '../Imagenes/Licenciatura_enfermeria.jpeg'
import Licenciatura_enfermeria2 from '../Imagenes/Licenciatura_enfermeria_practica.jpeg'

function LicenciaturaEnEnfermeria() {
  // Lista de imágenes para el carrusel
  const images = [
    { src: LicenciaturaEnfermeria, caption: 'Evaluación del estado físico y psicológico del paciente' },
    { src: Licenciatura_enfermeria, caption: 'Desarrollo de programas de salud' },
    { src: Licenciatura_enfermeria2, caption: 'Promoción de estilos de vida saludables' },
  ];

  // Estado para la imagen actual
  const [currentImage, setCurrentImage] = useState(0);

  // Funciones para cambiar la imagen actual
  const nextImage = () => {
    setCurrentImage((prevImage) => (prevImage + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentImage((prevImage) => (prevImage - 1 + images.length) % images.length);
  };

  return (
    <div className="licenciatura-en-enfermeria">
      <h1>Licenciatura en Enfermería</h1>
      <p>
        La Licenciatura en Enfermería es una carrera universitaria que forma profesionales capacitados para brindar cuidados de salud a las personas.
      </p>
      <ul>
        <li>Analizar el estado físico, social y psicológico del paciente.</li>
        <li>Realizar diagnósticos y recetar tratamientos.</li>
        <li>Prevenir enfermedades.</li>
        <li>Conocer procedimientos de primeros auxilios, emergencias e infecciones.</li>
        <li>Promover estilos de vida saludables.</li>
        <li>Desarrollar programas de salud y bienestar.</li>
      </ul>

      {/* Carrusel de fotos */}
      <div className="carrusel">
        <button onClick={prevImage} className="btn-prev">⬅️</button>
        <div className="imagen-container">
          <img src={images[currentImage].src} alt={`Imagen ${currentImage + 1}`} className="imagen-carrusel" />
          <p className="caption">{images[currentImage].caption}</p>
        </div>
        <button onClick={nextImage} className="btn-next">➡️</button>
      </div>

      {/* Descarga del PDF con el temario */}
      <div className="temario">
        <h2>Descargar Temario</h2>
        <a href={temarioPDF} download="Temario_Licenciatura_Enfermeria.pdf">
          <button className="btn-descargar">Descargar PDF</button>
        </a>
      </div>

      {/* Botón de contacto */}
      <div className="contacto">
        <button className="btn-contacto" onClick={() => window.location.href = '/contacto'}>
          Contáctanos
        </button>
      </div>
    </div>
  );
}

export default LicenciaturaEnEnfermeria;
