import React from 'react';
import './Footer.css';

function Footer() {
    return (
        <footer className='footer'>
            <ul className='footer-items'>
                <li><a href='https://jlcodedev.com/' className='footer-link'>Sobre Nosotros</a></li>
                <li className='footer-text'>CCEGYT - Habilidad y Profesionalismo</li>
                <li><a href='#politica' className='footer-link'>Política de privacidad</a></li>
            </ul>
        </footer>
    );
}

export default Footer;
