import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './componentes/Navbar';
import Footer from './componentes/Footer'
import Inicio from './componentes/Inicio';
import SobreNosotros from './componentes/SobreNosotros';
import OfertaEducativa from './componentes/OfertaEducativa';
import Contacto from './componentes/Contacto';
import DiplomadoEnfermeriaAuxiliar from './vistas/DiplomadoEnfermeriaAuxiliar';
import LicenciaturaEnEnfermeria from './vistas/LicenciaturaEnEnfermeria';
import DiplomadoEnTerapiasDeMasaje from './vistas/DiplomadoEnTerapiasDeMasaje';
import './App.css';



function App() {
  return (
    <Router>
      <div className='app'>
        <Navbar/>
        <Routes>
          <Route path='/' element={<Inicio/>}/>
          <Route path='/sobre-nosotros' element={<SobreNosotros/>}/>
          <Route path="/oferta-educativa" element={<OfertaEducativa />} />
          <Route path='/contacto' element={<Contacto/>}/>
          {/* Rutas para las nuevas vistas */}
          <Route path="/diplomado-enfermeria-auxiliar" element={<DiplomadoEnfermeriaAuxiliar/>} />
          <Route path="/licenciatura-en-enfermeria" element={<LicenciaturaEnEnfermeria/>} />
          <Route path="/diplomado-terapias-de-masaje" element={<DiplomadoEnTerapiasDeMasaje/>} />
        </Routes>
        <Footer/>
      </div>
    </Router>
  );
}

export default App;
