import React, { useState } from 'react';
import './Navbar.css';
import { Link } from 'react-router-dom';
import logo from '../Imagenes/CCGYT-LOGO.png';

function Navbar() {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header className='header'>
      <div className='header-container'>
        <div className='logo-container'>
          <img src={logo} alt='Instituto Logo' className='logo' />
        </div>
        <nav className='navbar'>
          <div className='burger-menu' onClick={toggleMenu}>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <ul className={`nav-menu ${menuOpen ? 'active' : ''}`}>
            <li className='navitem'><Link to='/'>Inicio</Link></li>
            <li className='navitem'><Link to='/sobre-nosotros'>Sobre Nosotros</Link></li>
            <li className='navitem'><Link to='/oferta-educativa'>Oferta Educativa</Link></li>
            <li className='navitem'><Link to='/contacto'>Contacto</Link></li>
          </ul>
        </nav>
      </div>
    </header>
  );
}

export default Navbar;
