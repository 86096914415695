import React, { useEffect, useRef } from 'react';
import './SobreNosotros.css';

function SobreNosotros() {
  const misionRef = useRef(null);
  const visionRef = useRef(null);
  const valoresRef = useRef(null);

  const handleScroll = () => {
    const refs = [misionRef, visionRef, valoresRef];
    const windowHeight = window.innerHeight;

    refs.forEach(ref => {
      const elementTop = ref.current.getBoundingClientRect().top;
      if (elementTop < windowHeight - 100) {
        ref.current.classList.add('aparece');
      }
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className="sobre-nosotros">
      {/* Sección: ¿Quiénes somos? (estática) */}
      <div className="seccion estatica">
        <h2>¿Quiénes somos?</h2>
        <p>
          Somos un centro de capacitación dedicados a la formación de profesionales de la salud desde el año 2019.
        </p>
        <p>
          Nos esforzamos en brindar una educación de calidad, combinando teoría y práctica para formar personas capaces de responder a las necesidades actuales del sector.
        </p>
      </div>

      {/* Sección: Misión (con animación) */}
      <div className="seccion" ref={misionRef}>
        <h3>Misión</h3>
        <p>
          Ser una de las mejores opciones en la formación de los profesionales de la salud, fortaleciendo la profesionalización en el área de la salud.
        </p>
        <p>
          Ofrecer una educación especializada y accesible en el campo de la salud, proporcionando los conocimientos y habilidades que permitan a nuestros alumnos ejercer con ética, profesionalismo y compromiso en su práctica diaria. Nos enfocamos en promover la mejora continua y en fortalecer la profesionalización del área.
        </p>
      </div>

      {/* Sección: Visión (con animación) */}
      <div className="seccion" ref={visionRef}>
        <h3>Visión</h3>
        <p>
          Seguir creciendo y convertirnos en uno de los mejores centros de capacitación para el fomento, la participación y profesionalismo del enfermero, gerontólogo y terapeuta.
        </p>
        <p>
          Convertirnos en uno de los centros de capacitación más destacados en el ámbito de la salud, siendo reconocidos por nuestra excelencia académica y por fomentar la participación activa de enfermeros, gerontólogos y terapeutas comprometidos con la mejora continua y el servicio a la sociedad.
        </p>
      </div>

      {/* Sección: Valores (con animación) */}
      <div className="seccion" ref={valoresRef}>
        <h3>Nuestros Valores</h3>
        <ul>
          <li className="value-item">
            <span className="value-text"><strong>Honestidad:</strong> Actuamos con transparencia y sinceridad, promoviendo una comunicación abierta y honesta en todos nuestros procesos.</span>
            <span className="value-icon" role="img" aria-label="honesty">✨</span>
          </li>
          <li className="value-item">
            <span className="value-text"><strong>Tolerancia:</strong> Respetamos y valoramos la diversidad de pensamiento, cultura y opiniones, fomentando un ambiente inclusivo y de respeto mutuo.</span>
            <span className="value-icon" role="img" aria-label="tolerance">🤝</span>
          </li>
          <li className="value-item">
            <span className="value-text"><strong>Respeto:</strong> Valoramos la dignidad de cada persona y promovemos el respeto como base fundamental en las relaciones humanas y profesionales.</span>
            <span className="value-icon" role="img" aria-label="respect">🕊️</span>
          </li>
          <li className="value-item">
            <span className="value-text"><strong>Lealtad:</strong> Nos comprometemos con nuestra misión y visión, apoyando siempre el crecimiento y desarrollo de nuestros estudiantes y comunidad.</span>
            <span className="value-icon" role="img" aria-label="loyalty">💖</span>
          </li>
          <li className="value-item">
            <span className="value-text"><strong>Responsabilidad:</strong> Fomentamos la responsabilidad en el aprendizaje y el compromiso con el bienestar de los pacientes y la comunidad.</span>
            <span className="value-icon" role="img" aria-label="responsibility">✅</span>
          </li>
          <li className="value-item">
            <span className="value-text"><strong>Esfuerzo:</strong> Creemos en la dedicación y el esfuerzo constante como medio para alcanzar los objetivos y superar los desafíos.</span>
            <span className="value-icon" role="img" aria-label="effort">💪</span>
          </li>
          <li className="value-item">
            <span className="value-text"><strong>Perseverancia:</strong> Promovemos la perseverancia como un valor esencial para el logro de metas y el desarrollo personal y profesional.</span>
            <span className="value-icon" role="img" aria-label="perseverance">🏆</span>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default SobreNosotros;
