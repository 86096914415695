import React, { useState, useEffect } from 'react';
import './DiplomadoEnTerapiasDeMasaje.css';
import temarioPDF from '../assets/temario_terapias_de_masaje.pdf';
import imagen1 from '../Imagenes//Diplomado_masajista.jpeg';
import imagen2 from '../Imagenes/Diplomado_masajes.jpeg';
import imagen3 from '../Imagenes/Diplomado_masa.jpeg';

function DiplomadoEnTerapiasDeMasaje() {
  const images = [
    { src: imagen1, caption: 'Masaje terapéutico para mejorar la circulación' },
    { src: imagen2, caption: 'Estimulación de la relajación y reducción de tensión' },
    { src: imagen3, caption: 'Mejora de la elasticidad y coordinación muscular' },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const nextImage = () => {
    setCurrentIndex((currentIndex + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentIndex((currentIndex - 1 + images.length) % images.length);
  };

  useEffect(() => {
    const interval = setInterval(nextImage, 5000);
    return () => clearInterval(interval);
  },);

  return (
    <div className="diplomado-terapias-masaje">
      <h1>Diplomado en Terapias de Masaje</h1>
      <p>
        El masaje es una forma de manipulación de las capas superficiales y profundas de los músculos del cuerpo utilizando varias técnicas, para mejorar sus funciones y ayudar en procesos de curación.
      </p>
      <ul>
        <li>Reducir la tensión y el dolor.</li>
        <li>Mejorar la circulación sanguínea.</li>
        <li>Estimular la relajación.</li>
        <li>Aumentar la elasticidad de la piel.</li>
        <li>Mejorar la coordinación muscular.</li>
        <li>Mejorar la nutrición y la digestión.</li>
        <li>Mejorar las habilidades cognitivas y de aprendizaje.</li>
      </ul>

      {/* Carrusel de fotos */}
      <div className="carrusel">
        <button className="prev" onClick={prevImage}>⬅️</button>
        <div className="image-container">
          <img src={images[currentIndex].src} alt={`Imagen ${currentIndex + 1}`} />
          <p className="caption">{images[currentIndex].caption}</p>
        </div>
        <button className="next" onClick={nextImage}>➡️</button>
      </div>

      {/* Descarga del PDF con el temario */}
      <div className="temario">
        <h2>Descargar Temario</h2>
        <a href={temarioPDF} download="Temario_Terapias_De_Masaje.pdf">
          <button className="btn-descargar">Descargar PDF</button>
        </a>
      </div>

      {/* Botón de contacto */}
      <div className="contacto">
        <button className="btn-contacto" onClick={() => window.location.href = '/contacto'}>
          Contáctanos
        </button>
      </div>
    </div>
  );
}

export default DiplomadoEnTerapiasDeMasaje;
